export const mapDict = (d, f, outputType = 'dict') => {

    let res = outputType === 'dict' ? {} : []

    for(const [key, value] of Object.entries(d)){

        const _ = f(key, value)

        if(outputType === 'dict'){
            res[key] = _
        } else {
            res.push(_)
        }
        
    }
    
    return res
}
import _ from 'lodash'
import React, {useState} from 'react'
import { Search } from 'semantic-ui-react'
import useGlobalContext from '../../hocs/useGlobalContext'
import { setCtxData } from '../../data/other'

import '../../static/css/search.scss'

const PlacesTableFilter = () => {

  const {initData, setData, data, geoLocData, setCtx, ctx, setCache, cache} = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')

  const filterData = (filterValue) => {
    let _newCtx = {...ctx}
    _newCtx.filters['search'] = filterValue

    setCtx(_newCtx)
    setCtxData(initData, _newCtx, cache, setData, setCache, geoLocData)
  }

  const handleSearchChange = (e, d) => {
    e.preventDefault()
    setLoading(true)
    setValue(d.value)

    filterData(d.value)
    setLoading(false)
  }

  return (
    <div id='search-btn'>
      <Search
          loading={loading}
          placeholder='Поиск...'
          onSearchChange={handleSearchChange}
          showNoResults={false}
          value={value}
          className={'search-input page-block'}
      />
    </div>
  )
}

export default PlacesTableFilter
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Home from '../../pages/Home'
import { GlobalProvider } from '../../store/GlobalStore'

import 'semantic-ui-css/semantic.min.css'

const App = () => {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  )
}

export default App;

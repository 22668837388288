import React, {useEffect} from "react";
import YandexMap from "../../components/YandexMap";
import PlacesTable from "../../components/PlacesTable";
import PlacesTableFilter from "../../components/PlacesTableFilter";
import H1 from "../../components/H1";
import LabelUpdatedAt from "../../components/LabelUpdatedAt";

import useGlobalContext from '../../hocs/useGlobalContext'
import {getDistanceFromLatLonInKm} from '../../utils/geo'

import { mapDict } from "../../utils/other";
import { setCtxData } from "../../data/other";

const DATA_LINK = process.env.PUBLIC_URL + '/data/data.json'
const CONFIG_LINK = process.env.PUBLIC_URL + '/data/config.json'

const Home = () => {

    const {setConfig, setData, setInitData, setGeoLoc, setGeoLocData, initData, config, geoLoc, ctx, cache, setCache, geoLocData} = useGlobalContext()

    const navigatorPositionCb = (position) => {

      const _geoLoc = [position.coords.latitude, position.coords.longitude]
      //обновляем геопозицию
      setGeoLoc(_geoLoc)
      console.log('Geoposition changed')
      
    }

    const fetchData = async (link, cb) => {
      try {
        let _data = await fetch(link);

        if (_data) {
          _data = await _data.json()
          cb(_data)

        }
      } catch(error) {
        console.log(error);
      }
    }

    const _cbData = (data) => {
        setInitData(data)
        setCtxData(data, ctx, cache, setData, setCache, geoLocData)
    }

    const _cbConfig = (data) => {
      setConfig(data)
      console.log(config)
    }

    //грузим data файл
    useEffect( () => {
      fetchData(DATA_LINK, _cbData)
      fetchData(CONFIG_LINK, _cbConfig)
    }, [])

    //ставим слушатель на изменение geoLocation
    useEffect( () => {

        let watchId;

        if ("geolocation" in navigator){
            watchId = navigator.geolocation.watchPosition( (position) => {
                navigatorPositionCb(position)
            });
        }

        return () => navigator.geolocation.clearWatch(watchId);
    }, [])

    useEffect( () => {
      let _geoLocData = {}

      mapDict(initData, (key, row) => {
        const _geo = getDistanceFromLatLonInKm(geoLoc, row.coords)
        _geoLocData[key] = {
          distanceToMe: _geo
        }
      })

      setGeoLocData(_geoLocData)
    }, [geoLoc])


    return (
        <>
            <H1 content={'Приложение для упрощения процесса бронирования ресторанов PrimeZone'} />
            <LabelUpdatedAt
              name="Дата обновления"
              detail={config.updated_at}
            />
            <PlacesTableFilter />
            <H1 content={'Карта'} />
            <YandexMap />
            <H1 content={'Таблица'} />
            <PlacesTable />
        </>
    )
}

export default Home



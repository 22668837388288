import React, { useRef } from "react";
import { YMaps, Map } from 'react-yandex-maps';
import UserPosition from '../UserPosition'
import UserPositionButton from "../UserPositionButton";
import YandexMapRenderedData from "../YandexMapRenderedData";

import useGlobalContext from '../../hocs/useGlobalContext'

const defaultZoom = 10

const YandexMap = () => { 

    const YMap = useRef(null)
    const {data, geoLoc} = useGlobalContext()

    const geolocControlClickHandler = () => {
        YMap.current.setCenter(geoLoc, YMap.current.getZoom())
    }

    return (
        <YMaps>
            <div className = "map page-block">
                <Map         
                    instanceRef={YMap}            
                    width="100%"
                    height="800px"
                    defaultState={{ 
                        center: geoLoc, 
                        zoom: defaultZoom,
                        }} >
                    <UserPosition coords={geoLoc} />
                    <YandexMapRenderedData data={data} />
                    <UserPositionButton callback={geolocControlClickHandler} />
                
                </Map>
            </div>
        </YMaps>
        )
}

export default YandexMap
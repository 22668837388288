const GlobalReducer = (state, action) => {
   switch(action.type) {
       case 'SET_DATA':
           return {
                ...state,
                data: action.payload
           }

        case 'SET_INIT_DATA':
            return {
                    ...state,
                    initData: action.payload
            }

       case 'SET_GEOLOC':
            return {
                ...state,
                geoLoc: action.payload
            }

        case 'SET_GEOLOC_DATA':
            return {
                ...state,
                geoLocData: action.payload
            }

        case 'SET_CACHE':

            return {
                ...state,
                cache: action.payload
            }

        case 'SET_CTX':

            return {
                ...state,
                ctx: action.payload
            }

        case 'SET_CONFIG':

            return {
                ...state,
                config: action.payload
            }

       default:
           return state;
   }
}

export default GlobalReducer
import React from 'react'
import { Label } from 'semantic-ui-react'

const LabelUpdatedAt = (props) => {
    return (
        <Label
        attached="top right"
        >
            {props.name}
            <Label.Detail>{props.detail}</Label.Detail>
        </Label>
    )
}

export default LabelUpdatedAt
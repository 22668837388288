import React, { createContext, useReducer } from 'react';
import GlobalReducer from '../reducers/globalReducer';

const defaultGeoLoc = [55.756658, 37.614037]

const initialState = {
  initData: {},
  config: {},
  data: [],
  geoLoc: defaultGeoLoc,
  geoLocData: {},
  cache: {},
  ctx: {
    'filters': {},
    'sort': {}
  }
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
   const [state, dispatch] = useReducer(GlobalReducer, initialState);

   // Actions for changing state

   function setData(item) {
       dispatch({
           type: 'SET_DATA',
           payload: item
       });
   }

   function setInitData(item) {
    dispatch({
        type: 'SET_INIT_DATA',
        payload: item
    });
    }

   function setGeoLoc(item) {
      dispatch({
          type: 'SET_GEOLOC',
          payload: item
    });
    }

    function setGeoLocData(item) {
        dispatch({
            type: 'SET_GEOLOC_DATA',
            payload: item
      });
    }

    function setCache(item) {
      dispatch({
          type: 'SET_CACHE',
          payload: item
    })
  }

    function setCtx(item) {
      dispatch({
          type: 'SET_CTX',
          payload: item
    });
    }

    function setConfig(item) {
      dispatch({
          type: 'SET_CONFIG',
          payload: item
    });
    }

   return(
      <GlobalContext.Provider value = {{...state, setData, setGeoLoc, setInitData, setGeoLocData, setCache, setCtx, setConfig}}> 
        {children} 
      </GlobalContext.Provider>
   )
}


import { mapDict } from "../utils/other"
import _ from 'lodash'
import {maxCacheSize} from '../config'

const sortData = (data, id, direction, geoLocData) => {

    let _data = [...data]
    let _dir 

    switch(direction){

      case 'ascending':
        _dir = 'asc'
        break 

      case 'descending':
        _dir = 'desc'
        break 

      default:
        throw new Error('No direction case named ' + (direction || ''))
    }

    if(id === 'distance'){
      _data = _data.map( (row) => {
          return {...row, distance: geoLocData[row.key].distanceToMe}
      })
    }

    _data = _.orderBy(_data, id, _dir)
    return _data

}

const iterColumnNames = [
    'address_txt',
    'org_name',
    'sale_perc'
  ]

const includesFilterValue = (row, filterValue) => {

    const _filterValue = String(filterValue).toLowerCase()

    for(const col of iterColumnNames){
        const _ = String(row[col]).toLowerCase()
        if(_.includes(_filterValue) ){
            return true
        }
    }
    
    return false

}

export const setCtxData = (initData, ctx, cache, setData, setCache, geoLocData) => {

    //exception when initData is empty
    if(Object.keys(initData).length == 0){
        return
    }

    //find out if context exists in cache
    const _ctxKey = JSON.stringify(ctx)
    const _cacheCtxData = cache && cache[_ctxKey]

    //if exists - return it 
    if(!!_cacheCtxData){
        setData(_cacheCtxData)
        return
    }

    //if not, filter by ctx and then sort by ctx

    let _data = mapDict(initData, (key, row) => ({...row, key}), 'arr')

    //filter

    _data = _data.filter( (row) => {
        for( const [filterName, filterValue] of Object.entries(ctx.filters)){

            switch(filterName){

                case 'search':
                    if(!includesFilterValue(row, filterValue)){
                        return false
                    }
                    break

                default:
                    if(filterValue != row[filterName]){
                        return false
                    }

            }

        }

        return true
    })

    //sort

    if(!!ctx.sort.id || !!ctx.sort.direction){
        _data = sortData(_data, ctx.sort.id, ctx.sort.direction, geoLocData)
    }
    
    //return result
    if( (ctx.sort.id || '') !== 'distance'){
        const _newCacheKey = JSON.stringify(ctx)
        const _cache = {...cache, [_newCacheKey]: _data}
        const _keys = Object.keys(_cache)
        
        //if cache size > maxxCacheSize then pop keys[0]
        if( _keys.length > maxCacheSize){
            delete _cache[_keys[0]]
        }

        setCache(_cache)

    }

    setData(_data)

}
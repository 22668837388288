import React, { useState } from 'react'
import useGlobalContext from '../../hocs/useGlobalContext'
import { Table } from 'semantic-ui-react'
import _ from 'lodash'
import { setCtxData } from '../../data/other'

import '../../static/css/places-table.scss'

const sortedChange = {
  'ascending': 'descending',
  'descending': 'ascending'
}

const defaultColumns = {
  org_name: 
    {
      'name': 'Название',
      'sorted': 'ascending',
      'isNumeric': false,
      'className': 'center aligned one wide'
    },
  sale_perc:
  {
    'name': 'Скидка',
    'sorted': false,
    'isNumeric': true,
    'className': 'center aligned one wide'
  },
  distance:
  {
    'name': 'Расстояние',
    'sorted': false,
    'isNumeric': true,
    'className': 'center aligned one wide'
  },
  address_txt: 
  {
    'name': 'Адрес',
    'sorted': false,
    'isNumeric': false,
    'className': 'center aligned three wide'
  }
}

const PlacesTable = () => {

    const [columns, setColumns] = useState(defaultColumns)
    const [rowClicked, setRowClicked] = useState(false)
    const {data, setData, initData, geoLocData, setCtx, ctx, setCache, cache} = useGlobalContext()

    const handleColumnClick = (e, id) => {
      e.preventDefault()

      //определяем новое положение колонок
      const sorted = columns[id].sorted

      let _sorted
      let _columns = {...columns}
      
      if(!sorted){
        _sorted = 'ascending'
      }
      else {
        _sorted = sortedChange[sorted]
      }

      for(const k of Object.keys(columns)){
        if(k === id){
          _columns[k].sorted = _sorted
        }

        else {
          _columns[k].sorted = false
        }

      }

      setColumns(_columns)

      //set ctx.sorted
      let _newCtx = {...ctx}
      _newCtx.sort = {
        id,
        direction: _sorted
      }

      setCtx(_newCtx)
      setCtxData(initData, _newCtx, cache, setData, setCache, geoLocData)
    }

    const handleRowClick = (e, rowKey) => {
      e.preventDefault()
      let _newCtx = {...ctx}

      if(rowClicked){

        //set filter context to initial
        _newCtx.filters = {}
        setRowClicked(false)

      } else {

        _newCtx.filters['key'] = rowKey
        setRowClicked(true)
      }

      setCtx(_newCtx)
      setCtxData(initData, _newCtx, cache, setData, setCache, geoLocData)

    }

    function formatDistance(distInKm){

      if(distInKm > 1){
        return `${Math.round(distInKm)} км`
      }

      return `${Math.round(distInKm * 1000)} м`

    }

    return (
      <Table celled sortable selectable compact size='small' striped className={'places-table page-block'}>
        <Table.Header>
          <Table.Row>
            {Object.keys(columns).map( (c) => {
                return (
                  <Table.HeaderCell 
                    id={columns[c].id}
                    key={columns[c].name}
                    sorted={columns[c].sorted ? columns[c].sorted : null}
                    onClick={(e) => handleColumnClick(e, c)}
                    className={columns[c].className}
                  >
                    {columns[c].name}
                  </Table.HeaderCell>
                )
              }
            )
          }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!data && data.map( (row) => {
              return (
                <Table.Row key={row.key} onClick={(e) => handleRowClick(e, row.key)}>
                  <Table.Cell verticalAlign='middle' textAlign='center'>{row.org_name}</Table.Cell>
                  <Table.Cell verticalAlign='middle' textAlign='center'>{ !!row.sale_perc ? row.sale_perc + '%' : '' }</Table.Cell>
                  <Table.Cell verticalAlign='middle' textAlign='center'>{ Object.keys(geoLocData || {}).length > 0 ? formatDistance(geoLocData[row.key].distanceToMe): ''}</Table.Cell>
                  <Table.Cell verticalAlign='middle' textAlign='center'>{row.address_txt}</Table.Cell>

                </Table.Row>
            )
          })
        }
        </Table.Body>

      </Table>
    )
}

export default PlacesTable
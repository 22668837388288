import React from 'react'
import Place from "../Place";

import useOffsetMetrics from "../../hocs/useOffsetMetrics";

const YandexMapRenderedData = ({data}) => {

    console.log('data rerender')

    const offsetMetrics = useOffsetMetrics()

    return data && data.map( (point) => {
            return (
            <Place {...point} balloonSettings={offsetMetrics} key={point.sale_add_info_url + '_' + JSON.stringify(point.coords) + '_' + point.address_txt} />
            )
        })

}

export default React.memo(YandexMapRenderedData)
import React from 'react'
import { Container, Divider } from 'semantic-ui-react'

const H1 = (props) => {
    return (
        <Container textAlign='center' className='page-block'>
            <h1>{props.content}</h1>
        </Container>
    )
}

export default H1